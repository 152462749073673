@mixin colorSvg($color, $animate: false) {
    &[fill]:not([fill="none"]):not([fill=""]) {
        @if ($animate) {
            transition: fill $euiAnimSpeedNormal;
        }
        fill: $color;
    }

    & path,
    g {
        @if ($animate) {
            transition: fill $euiAnimSpeedNormal, stroke $euiAnimSpeedNormal;
        }
        stroke: $color;

        &[fill]:not([fill="none"]):not([fill=""]) {
            fill: $color;
        }
    }
}
