.lxnavStatTitle {
    display: inline-flex;
    align-items: center;

    &__title {
        flex-grow: 0;
        color: $statBoxIconColor;
    }

    &__iconWrapper {
        border-radius: 50%;
        position: relative;
        background-color: $statBoxIconBackgroundColor;
        flex-shrink: 0;

        & > svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include colorSvg($statBoxIconColor);
        }
    }

    &--large {
        & .lxnavStatTitle__title {
            margin-left: 21px;
            font-size: 26px;
            line-height: 27px;
        }

        & .lxnavStatTitle__iconWrapper {
            width: 70px;
            height: 70px;

            & > svg {
                width: 50px;
                height: 50px;
            }
        }
    }

    &--medium {
        & .lxnavStatTitle__title {
            margin-left: 15px;
            font-size: 20px;
            line-height: 22px;
        }

        & .lxnavStatTitle__iconWrapper {
            width: 50px;
            height: 50px;

            & > svg {
                width: 30px;
                height: 30px;
            }
        }
    }

    &--small {
        & .lxnavStatTitle__title {
            margin-left: 8px;
            font-size: 14px;
            font-weight: 500;
            line-height: 15px;
        }

        & .lxnavStatTitle__iconWrapper {
            width: 30px;
            height: 30px;

            & > svg {
                width: 15px;
                height: 15px;
            }
        }
    }

    &--light {
        & .lxnavStatTitle__iconWrapper {
            background-color: $statBoxIconBackgroundColorLight;
        }
    }

    &--dark {
        & .lxnavStatTitle__iconWrapper {
            background-color: $statBoxIconBackgroundColorDark;
        }
    }

    $colorMap: (
        "danger": $euiColorDanger,
        "warning": $euiColorWarning,
    );

    @each $name, $color in $colorMap {
        &.lxnavStatTitle--#{$name} {
            & .lxnavStatTitle__title {
                color: $color;
            }

            & .lxnavStatTitle__iconWrapper {
                background-color: lighten($color, 5%);

                & > svg {
                    @include colorSvg(#ffffff);
                }
            }
        }
    }
}
