.lxnavSmallVesselMarker {
    $animationSize: 15px;
    $size: 14px;

    & svg {
        height: $size;
        width: $size;
        position: relative;
        z-index: 2;
    }

    &__animation {
        width: $animationSize;
        height: $animationSize;
        display: inline-block;
        position: absolute;
        top: calc(50% - 3px);
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
