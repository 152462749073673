.lxnavRippleAnimation {
    &__container {
        position: absolute;
        display: inline-block;
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }

    &--large {
        @include largeRippleAnimation;
    }

    &--small {
        @include smallRippleAnimation;
    }
}
