.lxnavApplicationLink {
    &:hover {
        text-decoration: underline;
        text-decoration-color: currentColor;
    }

    &.euiLink:focus {
        background-color: unset;
        animation: none !important;
    }

    &--unstyled.euiLink {
        background-color: unset;
        text-decoration: unset;
        outline: unset;
        color: unset;

        &:focus,
        &:hover {
            color: unset;
            outline: unset;
            background-color: unset;
            animation: none !important;
            text-decoration: none;
        }
    }
}
