@include euiHeaderAffordForFixed;

$_pageVerticalMarginSmall: 15px;
$_pageVerticalMarginMedium: 25px;
$_pageVerticalMarginLarge: 50px;

body,
#root {
  height: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 574px) {
  .lxnavFlexItemSmallPadding.euiFlexItem {
    margin-bottom: 4px !important;
  }
}

// Class applied to some pages in the app to center their content and limit the width
.lxnavPage {
  $pageWidth: 1200px;

  width: 100%;
  box-sizing: border-box;

  @include euiBreakpoint("xl") {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: $_pageVerticalMarginLarge;
  }

  @include euiBreakpoint("l", "m") {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: $_pageVerticalMarginMedium;
  }

  @include euiBreakpoint("s", "xs") {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: $_pageVerticalMarginSmall;
  }

  &__header {
    max-width: $pageWidth;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @include euiBreakpoint("xl") {
      margin-bottom: $_pageVerticalMarginLarge;
    }

    @include euiBreakpoint("l", "m") {
      margin-bottom: $_pageVerticalMarginMedium;
    }

    @include euiBreakpoint("s", "xs") {
      margin-bottom: $_pageVerticalMarginSmall;
    }
  }

  &__body {
    max-width: $pageWidth;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    background-color: unset;
    padding: unset;
  }
}

.lxnavVesselPopupContent {
  & .euiTitle {
    color: $euiColorInk;
  }

  padding: 18px;
  min-width: 200px;
}

.lxnavMenuItem {
  &:hover {
    text-decoration: underline;
  }

  &:focus {
    text-decoration: underline;
    background-color: transparent;
  }

  & span {
    padding: 1px;
  }
}

.lxnavTable {
  & .euiTableHeaderMobile > .euiFlexGroup {
    flex-direction: row-reverse;
  }

  & table {
    border-collapse: separate;
    background-color: unset;

    &.euiTable.euiTable--responsive {
      & .euiTableRow:hover {
        @include euiBreakpoint("s", "xs") {
          background-color: $cardLevelOneColor;
        }
      }

      & tbody tr td {
        @include euiBreakpoint("s", "xs") {
          background-color: transparent;
        }
      }
    }

    & thead {
      & th,
      td {
        border-bottom: none;
      }
    }

    & tbody tr {
      &.lxnavTable__row--focused {
        & td {
          background-color: lightOrDarkTheme($euiColorEmptyShade, $euiColorLightestShade);
        }
      }

      &.lxnavTable__row--danger {
        & td {
          background-color: lightOrDarkTheme(#ff9d9d, #963a3a);
        }
      }

      & td {
        background-color: $cardLevelTwoColor;
        border: unset;

        &:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }

  &--noHeaders {
    & table {
      & thead {
        display: none;
      }
    }
  }

  &--largeSpacing {
    & table {
      border-spacing: 0 15px;
    }
  }

  &--smallSpacing {
    & table {
      border-spacing: 0 2px;
    }
  }

  &--unstyledActions {
    & table tbody tr td.euiTableRowCell.euiTableRowCell--hasActions .euiTableCellContent__hoverItem {
      opacity: 1;
      filter: unset;
      transition: unset;
    }
  }
}

.lxnavIconButton {
  display: inline-flex;
  box-sizing: content-box;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &--padded {
    padding: 20px;
  }
}

.lxnavStickySection {
  position: sticky;
  top: 0;
  background-color: $euiPageBackgroundColor;
  border-bottom: 1px solid $euiColorLightShade;
}

.lxnavZLevelModal {
  z-index: $euiZModal;
}

.lxnavTruncatableFlexChild {
  min-width: 0;
}

.lxnavFillParent {
  width: 100%;
  height: 100%;
}

.lxnavBoldText {
  font-weight: 500;
}

.lxnavVesselPopoverMenu__content {
  @include euiScrollBar;

  overflow: auto;
  max-height: 300px;
}

.lxnavCompressedModalBody.euiModalBody .euiModalBody__overflow {
  padding-left: 5px;
  padding-right: 5px;
}

.lxnavOutlineBox {
  border: 1px solid lightOrDarkTheme(#dee1e6, #4f545e);
  box-sizing: border-box;
  border-radius: 10px;

  @include euiBreakpoint("s", "xs") {
    padding: 5px;
  }

  @include euiBreakpoint("m", "l", "xl") {
    padding: 10px;
  }
}

.lxnavThemedSvg {
  @include colorSvg($euiColorFullShade);

  &--light {
    @include colorSvg($svgLightColor);
  }
}

.lxnavWarningsPage {
  &__dismissButton {
    color: $euiTextSubduedColor;
  }
}

.lxnavSelectable {
  & .euiSelectableList {
    &:focus,
    &:focus-within {
      animation: none !important;
    }
  }
}

.lxnavMapIcon {
  @include colorSvg(#333646);

  &.lxnavMapIcon--disabled {
    @include colorSvg(#bfc5ce);
  }
}

.lxnavHomePage__mapControlSection {
  transition: transform $euiAnimSpeedNormal $euiAnimSlightResistance;
}