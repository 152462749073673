.lxnavVesselCard {
    display: inline-block;
    border-radius: 10px;
    background-color: $euiColorEmptyShade;
    width: 100%;

    background-image: lightOrDarkTheme(
        url("../../../../assets/backgrounds/transmitting_light.svg"),
        url("../../../../assets/backgrounds/transmitting_dark.svg")
    );
    background-repeat: no-repeat;
    background-position-x: center;

    transition: box-shadow $euiAnimSpeedFast;

    &:hover {
        box-shadow: 0 0 24px 4px rgba(0, 0, 0, 0.15);
    }

    &__content {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 325px;
        padding: 30px;

        &__header {
            display: inline-flex;
            justify-content: space-between;
        }

        &__body {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &.euiLink {
                &:hover {
                    text-decoration: none;
                }

                &:focus {
                    background-color: unset;
                    text-decoration: none;
                    outline: none;
                }
            }

            &__image {
                margin: auto;
                display: block;
                filter: drop-shadow(0px 1px 7px rgba(0, 0, 0, 0.35));
            }

            &__info {
                &__title {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    &--small {
        $itemSpacing: 5px;
        background-image: none;

        & .lxnavVesselCard__content {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 20px;
            min-height: unset;
            margin: 0 -$itemSpacing;

            &__body {
                margin: 0 $itemSpacing;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                min-width: 0;

                &__info {
                    min-width: 0;
                    flex-grow: 1;
                }

                &__image {
                    flex-shrink: 0;
                    margin-right: 30px;
                }
            }

            &__controls {
                flex-grow: 0;
                margin: 0 $itemSpacing;
            }
        }
    }
}
