.lxnavMessageBox {
    min-height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $euiFocusBackgroundColor;
    border-radius: $euiBorderRadius;

    &--error {
        color: #d35d4a;

        & > .lxnavMessageBox__icon {
            width: 12px;
        }
    }

    &--success {
        color: #8fd34a;

        & > .lxnavMessageBox__icon {
            width: 16px;
        }
    }

    &__icon {
        margin-right: 16px;

        & path {
            stroke: currentColor;
        }
    }
}
