.lxnavGeoRestIndicator {
  $color: $lxnavColorDanger;
  $areaColor: transparentize($color, .8);

  width: 20px;
  height: 20px;
  display: inline;

  border: 2px solid $color;
  border-radius: 5px;

  &--restrictedInside {
    background-color: $areaColor;
  }

  &--restrictedOutside {
    box-shadow: 0 0 0 5px $areaColor;
  }
}