.lxnavDataDisplay {
    white-space: nowrap;

    &__mainNumber,
    &__unit {
        letter-spacing: 0;
        font-style: normal;
    }

    &--large {
        & .lxnavDataDisplay__mainNumber {
            font-size: 48px;
            font-weight: 400;
            line-height: 48px;
        }

        & .lxnavDataDisplay__unit {
            font-size: 32px;
            font-weight: 400;
            line-height: 32px;
        }
    }

    &--medium {
        & .lxnavDataDisplay__mainNumber {
            font-size: 32px;
            font-weight: 400;
            line-height: 32px;
        }

        & .lxnavDataDisplay__unit {
            font-size: 24px;
            font-weight: 400;
            line-height: 24px;
        }
    }

    &--small {
        & .lxnavDataDisplay__mainNumber {
            font-size: 22px;
            font-weight: 400;
            line-height: 22px;
        }

        & .lxnavDataDisplay__unit {
            margin-left: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
        }
    }

    &--extraSmall {
        & .lxnavDataDisplay__mainNumber {
            font-size: 18px;
            font-weight: 400;
            line-height: 18px;
        }

        & .lxnavDataDisplay__unit {
            margin-left: 0;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
        }
    }

    $colorMap: (
        "danger": $euiColorDanger,
        "warning": $euiColorWarning,
    );

    @each $name, $color in $colorMap {
        &--#{$name} {
            & .lxnavDataDisplay__mainNumber {
                color: $color;
            }

            & .lxnavDataDisplay__unit {
                color: $color;
            }
        }
    }
}
