.lxnavAnchorButton {
  width: 32px;
  height: 32px;
  display: inline-block;

  border-style: solid;
  border-width: 2px;
  border-radius: 50%;

  & > svg {
    width: 14px;
    height: 15px;

    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &--on {
    border-color: $lxnavColorAnchorOn;

    & > svg {
      @include colorSvg($lxnavColorAnchorOn);
    }
  }

  &--off.lxnavAnchorButton {
    border-color: $lxnavColorAnchorOff;

    & > svg {
      @include colorSvg($lxnavColorAnchorOff);
    }
  }

  &--disabled.lxnavAnchorButton {
    border-color: $lxnavColorAnchorDisabled;
    &:hover {
      cursor: not-allowed;
    }

    & > svg {
      @include colorSvg($lxnavColorAnchorDisabled);
    }
  }
}