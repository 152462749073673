@import "common";

.lxnavVesselMarker {
    $size: 40px;
    $animationSize: 30px;

    & img {
        position: relative;
        height: $size;
        width: $size;
        z-index: 2;
    }

    &__indicator {
        &--online,
        &--offline {
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
        }

        &--online {
            width: 4px;
            height: 4px;
            box-sizing: content-box;
            background-clip: padding-box;
            background-color: $onlineColor;
            border: 2px solid transparentize($onlineColor, 0.8);
        }

        &--offline {
            @include offlineAnimation;

            width: 6px;
            height: 6px;
            background-color: $offlineColor;
        }
    }

    &__animation {
        display: inline-block;
        width: $animationSize;
        height: $animationSize;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
