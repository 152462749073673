.lxnavInfoPoint {
    $pointCircleSize: 28px;
    $pointCircleSizeSmall: 23px;

    $pointSymbolMargin: 27px;
    $pointColor: $euiColorLightShade;

    $scalingFactor: 1.1;

    $connectorWidth: 1px;

    // Create border on all but the last step
    &:not(:last-of-type) {
        background-image: linear-gradient(
            to right,
            transparent 0,
            transparent ($pointCircleSize / 2 - 1),
            $pointColor ($pointCircleSize / 2 - 1),
            $pointColor ($pointCircleSize / 2),
            transparent ($pointCircleSize / 2),
            transparent 100%
        );

        background-repeat: no-repeat;
        background-position: left $pointCircleSizeSmall;
    }

    transition: background-color $euiAnimSpeedFast, color $euiAnimSpeedFast;

    &__header {
        display: flex;

        &__content {
            display: inline-block;
            width: $pointCircleSize;
            height: $pointCircleSize;
            border-radius: 50%;
            text-align: center;
            vertical-align: top;
            line-height: $pointCircleSize;
            font-weight: $euiFontWeightMedium;
            background-color: $pointColor;
            transition: transform $euiAnimSpeedNormal;
            margin-right: $pointSymbolMargin;
        }
    }

    &__body {
        padding: 5px $euiSize $euiSizeXL;

        // Align the content's contents with the title
        padding-left: ($pointCircleSize / 2) + $pointSymbolMargin;

        // Align content border to horizontal center of step number
        margin-left: ($pointCircleSize / 2);
    }

    &--small {
        & .lxnavInfoPoint__header {
            margin-left: ($pointCircleSize - $pointCircleSizeSmall) / 2;

            &__content {
                width: $pointCircleSizeSmall;
                height: $pointCircleSizeSmall;
                line-height: $pointCircleSizeSmall;
            }
        }
    }

    &--focused {
        & .lxnavInfoPoint__header {
            &__content {
                background-color: $euiColorPrimary;
                color: $euiColorInk;
            }

            &__title {
                font-weight: $euiFontWeightSemiBold;
            }
        }
    }

    &--clickable {
        &:hover {
            & .lxnavInfoPoint__header {
                &__content {
                    transform: scale($scalingFactor);
                }
            }

            cursor: pointer;
        }
    }
}
