.lxnavMapButton {
    height: 36px;
    border-radius: $euiBorderRadius;
    min-width: 120px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    transition: box-shadow $euiAnimSpeedExtraFast;

    $colorDisabled: #a7a7a7;
    $backgroundColorDisabled: #dddddd;

    &__label {
        font-size: 14px;
    }

    &__icon {
        fill: none;
    }

    &:hover:not(:disabled) {
        cursor: pointer;
    }

    &:active {
        box-shadow: none;
    }

    &:disabled {
        background-color: $backgroundColorDisabled;
        box-shadow: none;

        & .lxnavMapButton__label {
            color: $colorDisabled;
        }

        & .lxnavMapButton__icon {
            @include colorSvg($colorDisabled);
        }
    }

    $colors: (
        'mixed': (
            'textColor': darken($euiColorPrimary, 6.5%),
            'backgroundColor': #fff
        ),
        'ghost': (
            'textColor': $euiColorInk,
            'backgroundColor': #fff
        ),
        'primary': (
            'textColor': $euiColorInk,
            'backgroundColor': $euiColorPrimary
        )
    );

    @each $color, $map in $colors {
        $textColor: map-get($map, 'textColor');
        $backgroundColor: map-get($map, 'backgroundColor');

        &--#{$color} {
            background-color: $backgroundColor;
            box-shadow: none;
    
            & .lxnavMapButton__label {
                color: $textColor;
            }
    
            & .lxnavMapButton__icon {
                @include colorSvg($textColor);
            }

            &:hover:not(:disabled) {
                background-color: darken($backgroundColor, 3) !important;
            }
        }
    }
}
