.lxnavTwoTouchDragGuard {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: $euiZLevel11;
  pointer-events: none;

  &--visible {
    & .lxnavTwoTouchDragGuard__inner {
      opacity: 1;
    }
  }

  &__inner {
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: rgba(0,0,0,.45);
    color: $euiColorGhost;
    font-size: $euiFontSizeL;
    transition: opacity ease-in-out $euiAnimSpeedNormal;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
  }
}

.lxnavMapTwoTouchDragGuard {
  & .mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan {
    touch-action: auto;

    & .mapboxgl-canvas{
      touch-action: auto;
    }
  }
}