// Finally start using the non-beta version of Inter
$euiFontFamily: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

// Font sizes -- scale is loosely based on Major Third (1.250) with a base of 14px
// Base list is an altered scale based on 16px to match the resulted values below
$euiTextScale: 2.125, 1.6875, 1.375, 1.125, 1, 0.875, 0.75;

$euiFontSize: $euiSize - 2; // 14px

$euiFontSizeXS: floor($euiFontSize * 0.86); // 12px // h6
$euiFontSizeS: floor($euiFontSize * 1); // 14px // h5 --> Now the same as the base $euiFontSize
$euiFontSizeM: ceil($euiFontSize * 1.14); // 16px // h4
$euiFontSizeL: ceil($euiFontSize * 1.57); // 22px // h3
$euiFontSizeXL: floor($euiFontSize * 1.93); // 27px // h2
$euiFontSizeXXL: floor($euiFontSize * 2.43); // 34px // h1

$euiTitles: (
    "xxxs": (
        "font-size": $euiFontSizeXS,
        //'line-height': lineHeightFromBaseline(3),
        //'font-weight': $euiFontWeightBold,,
    ),
    "xxs": (
        "font-size": $euiFontSizeS,
        //'line-height': lineHeightFromBaseline(3),
        //'font-weight': $euiFontWeightBold,,
    ),
    "xs": (
        "font-size": $euiFontSizeM,
        //'line-height': lineHeightFromBaseline(3),
        //'font-weight': $euiFontWeightBold,,
    ),
    "s": (
        "font-size": $euiFontSizeL,
        //'line-height': lineHeightFromBaseline(4),
        //'font-weight': $euiFontWeightBold,,
    ),
    "m": (
        "font-size": $euiFontSizeXL,
        //'line-height': lineHeightFromBaseline(5),
        //'font-weight': $euiFontWeightBold,,
    ),
    "l": (
        "font-size": $euiFontSizeXXL,
        //'line-height': lineHeightFromBaseline(6),
        //'font-weight': $euiFontWeightBold,,
    ),
);
