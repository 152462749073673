.lxnavStatBox {
    border-radius: 10px;
    background-color: $cardLevelTwoColor;
    flex-direction: column;
    box-sizing: border-box;

    @include euiBreakpoint("xl", "l", "m") {
        min-height: 200px;
        padding: 30px 20px 30px 20px;
    }

    @include euiBreakpoint("s", "xs") {
        min-height: 160px;
        padding: 20px 30px;
    }

    &--danger {
        background-color: $euiColorDanger;

        & .lxnavStatBox__titleSection {
            & .lxnavStatBox__title {
                color: #ffffff;
            }

            & .lxnavStatBox__icon {
                background-color: lighten($euiColorDanger, 10%);

                & > svg {
                    @include colorSvg(#ffffff);
                }
            }
        }

        & .lxnavStatBox__subtitle__label {
            color: #ffffff;
        }
    }

    &--warning {
        border: 3px solid $euiColorWarning;
        background-color: lighten($euiColorWarning, 35%);

        & .lxnavStatBox__titleSection {
            & .lxnavStatBox__title {
                color: $euiColorWarning;
            }

            & .lxnavStatBox__icon {
                background-color: $euiColorWarning;

                & > svg {
                    @include colorSvg(#ffffff);
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__innerContent {
        padding-bottom: 2px;
    }

    &__titleSection {
        display: flex;
        align-items: center;

        & .lxnavStatBox__title {
            color: lightOrDarkTheme($statBoxTitleColorLight, $statBoxTitleColorDark);
            font-size: 20px;
            line-height: 24px;
            margin-left: 20px;
        }

        & .lxnavStatBox__icon {
            width: 50px;
            height: 50px;
            position: relative;
            border-radius: 50%;
            background-color: lightOrDarkTheme($statBoxIconBackgroundColorLight, $statBoxIconBackgroundColorDark);

            & > svg {
                width: 30px;
                height: 30px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                @include colorSvg(lightOrDarkTheme($statBoxIconColorLight, $statBoxIconColorDark));
            }
        }
    }

    &__subtitle {
        height: 20px;

        @include euiBreakpoint("xl", "l", "m") {
            padding-bottom: 34px;
        }

        @include euiBreakpoint("s", "xs") {
            padding-bottom: 20px;
        }

        &__label {
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            color: $euiColorDarkestShade;
        }
    }
}
