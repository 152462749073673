.lxnavDotMarker {
    $types: (
        "small": (
            "size": 24px,
            "fontSize": 12px,
            "fontWeight": bold,
            "zIndex": 4,
            "zIndexLbl": 3,
        ),
        "medium": (
            "size": 30px,
            "fontSize": 15px,
            "fontWeight": bolder,
            "zIndex": 2,
            "zIndexLbl": 1,
        ),
    );

    background-color: #5f6b6f;
    color: $euiColorGhost;
    text-align: center;
    white-space: nowrap;
    border-radius: 50%;

    @each $name, $params in $types {
        &--#{$name} {
            height: map-get($params, "size");
            width: map-get($params, "size");
            z-index: map-get($params, "zIndex");
            font-size: map-get($params, "fontSize");
            font-weight: map-get($params, "fontWeight");
            line-height: map-get($params, "size");

            @if (map-get($params, "shadow")) {
                filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
            }
        }
    }

    &.lxnavDotMarker--selected {
        background-color: $euiColorPrimary;
    }
}
