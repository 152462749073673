// This file has lots of modifiers and is somewhat nesty by nature
//sass-lint:disable nesting-depth

.euiButton {
    // sass-lint:disable no-important
    // remove for all states
    box-shadow: none !important;
    font-weight: 400;

    // Added exclusion of the `ghost` type of button
    // so as not to override those specific styles from default theme
    // And the only style that needs to change is the non-filled version
    &:disabled:not(.euiButton--ghost):not(.euiButton--fill) {
        $backgroundColorSimulated: mix($euiPageBackgroundColor, $euiButtonColorDisabled, 70%);
        background-color: transparentize($euiButtonColorDisabled, 0.7);
        color: makeHighContrastColor($euiButtonColorDisabled, $backgroundColorSimulated, 2);
    }
}

.euiButton--small {
    // Use a moderately smaller radius on small buttons
    // so that they don't appear completely rounded
    border-radius: $euiBorderRadius * 0.667;
}

// Change the hollow (bordered) buttons to have a transparent background
// and no border
@each $name, $color in $euiButtonTypes {
    .euiButton--#{$name} {
        &:enabled {
            &:hover,
            &:focus {
                background-color: transparentize($color, 0.9);
            }

            &.euiButton--fill {
                &:hover,
                &:focus {
                    background-color: darken($color, 10%);
                }
            }
        }

        &:not(.euiButton--fill) {
            border: 1px solid $color;
        }
    }
}

// Fix ghost/disabled look specifically
.euiButton:disabled.euiButton--ghost:not(.euiButton--fill) {
    &,
    &:hover,
    &:focus {
        background-color: transparentize($euiButtonColorGhostDisabled, 0.7);
    }
}
