.lxnavCompass {
    user-select: none;

    @include euiBreakpoint("m", "l", "xl") {
        padding: 20px 5px 5px 20px;
    }

    &__container {
        position: relative;
        pointer-events: all;
        cursor: pointer;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
    }

    &__needleWrapper {
        pointer-events: all;
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        & > img {
            display: block;
            height: 30px;
        }
    }

    &__face {
        display: block;
        height: 50px;
    }
}
