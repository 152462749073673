.lxnavGeofenceTable {
  &__row {
    @extend .lxnavCard;
    @extend .lxnavCard--levelOne;
    padding: 20px;
    cursor: pointer;
  }

  &__editButton {
    width: 16px;
    height: 16px;
    cursor: pointer;
    @include colorSvg(lightOrDarkTheme(#adafb5, #d1d1d1))
  }

  &__indicator {
    padding-right: 16px;
  }
}