/**
 * 1. Allow list to expand as items are added, but cap it at the screen height.
 * 2. Only show the scroll on hover. Generally, scrolling is bad for toasts.
 * 3. Allow some padding if a scroll shows up.
 */

$toastHalfWidth: ($euiToastWidth + $euiSize + $euiSizeXL) / 2;

.euiGlobalToastList {
    @include euiScrollBar;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: fixed;
    z-index: $euiZToastList;
    bottom: initial;
    right: initial;
    width: $euiToastWidth + $euiSize + $euiSizeXL; /* 3 */
    padding-right: $euiSize;
    padding-left: $euiSizeXL;
    max-height: 100vh; /* 1 */
    top: $euiHeaderHeightCompensation + 10px;
    left: calc(50% - #{$toastHalfWidth});
    overflow-x: hidden;
    /*margin-left: -($euiToastWidth + $euiSize + $euiSizeXL) / 2;*/

    &:hover {
        overflow-y: initial; /* 2 */
    }
}

.euiGlobalToastListItem {
    margin-bottom: $euiSize;
    margin-right: $euiSize;
    animation: $euiAnimSpeedNormal euiShowToast $euiAnimSlightResistance;
    opacity: 1;

    /**
   * 1. justify-content: flex-end interferes with overflowing content, so we'll use this to push
   *    items to the bottom instead.
   */
    &:first-child {
        margin-top: auto; /* 1 */
    }

    &.euiGlobalToastListItem-isDismissed {
        transition: opacity $euiAnimSpeedNormal;
        opacity: 0;
    }
}

@keyframes euiShowToast {
    from {
        transform: translateY($euiSizeL) scale(0.9);
        opacity: 0;
    }

    to {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}

@include euiBreakpoint("xs", "s") {
    /**
   * 1. Mobile we make these 100%. Matching change happens on the item as well.
   */
    .euiGlobalToastList {
        left: 0;
        padding-left: $euiSize;
        width: 100%; /* 1 */
    }
}
