.lxnavGroupBox {
    $paddingSmall: 10px;
    $headerPaddingSmall: 12px;

    $paddingMedium: 20px;
    $headerPaddingMedium: 20px;

    $paddingLarge: 40px;
    $headerPaddingLarge: 34px;

    border-radius: 10px;

    &--levelOne {
        background-color: $cardLevelOneColor;
    }

    &--levelTwo {
        background-color: $cardLevelTwoColor;
    }

    &--paddingSmall {
        &.lxnavGroupBox--noHeaderPadding {
            padding: $paddingSmall;
        }

        &:not(.lxnavGroupBox--noHeaderPadding) {
            padding: $headerPaddingSmall $paddingSmall $paddingSmall $paddingSmall;

            & .lxnavGroupBox__header {
                padding-bottom: $headerPaddingSmall;
            }
        }
    }

    &--paddingMedium {
        &.lxnavGroupBox--noHeaderPadding {
            padding: $paddingMedium;
        }

        &:not(.lxnavGroupBox--noHeaderPadding) {
            padding: $headerPaddingMedium $paddingMedium $paddingMedium $paddingMedium;

            & .lxnavGroupBox__header {
                padding-bottom: $headerPaddingMedium;
            }
        }
    }

    &--paddingLarge {
        &.lxnavGroupBox--noHeaderPadding {
            padding: $paddingLarge;
        }

        &:not(.lxnavGroupBox--noHeaderPadding) {
            padding: $headerPaddingLarge $paddingLarge $paddingLarge $paddingLarge;

            & .lxnavGroupBox__header {
                padding-bottom: $headerPaddingLarge;
            }
        }
    }
}
