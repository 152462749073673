.lxnavDateRangePicker {
    max-width: $euiFormMaxWidth;

    &--full-width {
        max-width: 100%;
    }

    &__wrapper {
        width: 100%;
        display: inline-block;
        cursor: pointer;
        white-space: nowrap;
    }

    &__controlContainer {
        @extend .euiDatePickerRange;
        position: relative;

        max-width: 100%;
        border-radius: $euiFormControlBorderRadius;
    }

    &__datePicker {
        &.euiDatePicker.euiFieldText {
            background-image: unset;
            border: unset;
            background-color: unset;
            background-repeat: no-repeat;
            background-size: unset;
            transition: none;
            box-shadow: none;
            min-width: 155px;
            padding: 0 5px 0 10px;
        }

        &--hasValue {
            &.euiDatePicker.euiFieldText {
                text-align: left;
            }
        }
    }

    &__icon {
        pointer-events: none;
        flex-grow: 0;
        margin-right: 12px;

        // If the screen is narrower than 350px just dont display this
        @media screen and (max-width: 350px) {
            display: none;
        }
    }

    &--inline {
        & .lxnavDateRangePicker__title {
            color: $euiTextSubduedColor;
        }

        & .lxnavDateRangePicker__controlContainer {
            background-color: transparent;

            & .euiFormControlLayoutIcons--right {
                left: auto;
                right: 6px;
            }
        }

        & .lxnavDateRangePicker__icon {
            display: none;
        }
    }
}

.react-datepicker-popper {
    &.lxnavDatePickerPopper {
        @include euiBottomShadowMedium;

        border: $euiBorderThin;
        background-color: $euiColorEmptyShade;
        border-radius: 0 0 $euiBorderRadius $euiBorderRadius;

        & .react-datepicker {
            $dayBorderWidth: 2px;
            $rangeColor: transparentize($euiColorPrimary, 0.9);

            &__day-name {
                margin: 0;
            }

            & .react-datepicker__time-list {
                overflow-x: hidden; // Weird issue with firefox showing x scrollbar even though there is enough space
            }

            &__navigation--next {
                &--with-time:not(&--with-today-button) {
                    // This a pixel value against the width of the cal. It needs
                    // to be left because the timepicker adds more width
                    left: 220px;
                }
            }

            &__day {
                padding: 0;
                margin: 3px 0;

                &:hover {
                    transform: none;
                }

                &--keyboard-selected {
                    border-radius: 50%;
                }

                &--selected {
                    border-radius: 50%;
                    color: lightOrDarkTheme($euiColorGhost, $euiColorInk);

                    &.react-datepicker__day--in-range {
                        position: relative;
                        z-index: 1;

                        &.react-datepicker__day--range-start {
                            &::after {
                                content: "";
                                position: absolute;
                                display: inline-block;
                                width: 50%;
                                top: -2px;
                                left: calc(50% + 2px);
                                height: 100%;
                                height: calc(100% + 4px);
                                background-color: $rangeColor;
                                z-index: -1;
                            }
                        }

                        &.react-datepicker__day--range-end {
                            &::after {
                                content: "";
                                position: absolute;
                                display: inline-block;
                                width: 50%;
                                top: -2px;
                                right: calc(50% + 2px);
                                height: calc(100% + 4px);
                                background-color: $rangeColor;
                                z-index: -1;
                            }
                        }
                    }
                }

                &--in-range {
                    border-color: transparent !important;

                    &.react-datepicker__day--range-start:not(.react-datepicker__day--selected) {
                        border-top-left-radius: 50%;
                        border-bottom-left-radius: 50%;
                    }

                    &.react-datepicker__day--range-end:not(.react-datepicker__day--selected) {
                        border-top-right-radius: 50%;
                        border-bottom-right-radius: 50%;
                    }
                }
            }
        }
    }
}
