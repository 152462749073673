.lxnavTinyStatBox {
    $colorLight: #f5f6f8;
    $colorDark: #6f6f70;

    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-color: lightOrDarkTheme($colorLight, $colorDark);
    padding: 6px 10px;

    &.lxnavTinyStatBox--light {
        border-color: $colorLight;
    }

    &.lxnavTinyStatBox--dark {
        border-color: $colorDark;
    }

    &__content {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 3px;
    }

    &__title {
        color: $statBoxIconColor;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: left;
        margin-left: 3px;
    }

    &__icon {
        margin-right: 6px;

        & > svg {
            width: 20px;
            height: 20px;
            @include colorSvg($statBoxIconColor);
        }
    }
}
