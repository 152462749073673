// This import allows any variables that are created via functions to work when loaded into the app
@import "~@elastic/eui/src/global_styling/variables/colors";

$lxnavColorAttention: #ff9330 !default;
$lxnavColorDanger: #ef4d2a !default;
$lxnavColorInfo: #8c99ab !default;

$statBoxIconBackgroundColorDark: #2b2d3d !default;
$statBoxIconBackgroundColorLight: #eff2f5 !default;

$statBoxIconColorDark: #8c99ab !default;
$statBoxIconColorLight: #8c99ab !default;

$statBoxTitleColorDark: #8c99ab !default;
$statBoxTitleColorLight: #8c99ab !default;

$cardLevelOneColorDark: #222431 !default;
$cardLevelTwoColorDark: #1b1d27 !default;

$cardLevelOneColorLight: #fff !default;
$cardLevelTwoColorLight: #f7f8fa !default;
