.lxnavTriStateSwitch {
    $switchWidth: 46px;
    $switchHeight: 20px;
    $switchThumbSize: 24px;
    $thumbBorderWidth: 2px;
    $iconMaxSize: 14px;

    $scaleActive: 0.95;
    $scaleHover: 1.05;

    $switchThumbOffset: floor($switchThumbSize * $scaleActive - $switchThumbSize);

    position: relative;

    &__button {
        line-height: 0; // ensures button takes height of switch inside
    }

    &__body {
        pointer-events: none;
        width: $switchWidth;
        height: $switchHeight;
        display: inline-block;
        position: relative;
        border-radius: $switchHeight;
        vertical-align: middle;
    }

    &__thumb {
        padding: round($switchThumbSize / 2 - $thumbBorderWidth);
        border-radius: $switchThumbSize;
        z-index: 1;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        position: absolute;
        display: inline-block;
        left: 0;
        top: round(($switchHeight - $switchThumbSize) / 2);
        transition: background-color $euiAnimSpeedNormal, left $euiAnimSpeedNormal $euiAnimSlightBounce,
            transform $euiAnimSpeedNormal $euiAnimSlightBounce;

        & svg {
            position: absolute;
            display: inline-block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: $iconMaxSize;
            max-height: $iconMaxSize;
            height: 100%;
            width: 100%;
        }
    }

    &__track {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        border-radius: $switchHeight;
        transition: background-color $euiAnimSpeedNormal;
    }

    &:hover .lxnavTriStateSwitch__button {
        &.lxnavTriStateSwitch__thumb {
            transform: scale($scaleHover);
        }

        &:active .lxnavTriStateSwitch__thumb {
            transform: scale($scaleActive);
        }
    }

    & .lxnavTriStateSwitch__thumb {
        border-style: solid;
        border-width: $thumbBorderWidth;
    }

    &--checked {
        & .lxnavTriStateSwitch__thumb {
            left: $switchWidth - $switchThumbSize - $switchThumbOffset;
        }
    }

    &--unchecked {
        & .lxnavTriStateSwitch__thumb {
            left: 0 + $switchThumbOffset;
        }
    }

    &--intermidiate {
        & .lxnavTriStateSwitch__thumb {
            left: ($switchWidth - $switchThumbSize) / 2;
        }
    }

    &--disabled {
        & .lxnavTriStateSwitch__button {
            &:hover {
                cursor: not-allowed;
            }
        }

        & .lxnavTriStateSwitch__thumb {
            box-shadow: none;
        }
    }

    $primaryColorMap: (
        // Un-checked
        "colorTrackUnchecked": lightOrDarkTheme(#e1e6eb, #ffffff),
        "colorThumbUnchecked": #ffffff,
        "colorThumbBorderUnchecked": #ffffff,
        "colorThumbIconUnchecked": #e1e6eb,
        // Intermidiate
        "colorTrackIntermidiate": lightOrDarkTheme(#dfe4e9, #e1e6eb),
        "colorThumbIntermidiate": #ffffff,
        "colorThumbBorderIntermidiate": #ffffff,
        "colorThumbIconIntermidiate": $euiColorPrimary,
        // Checked
        "colorTrackChecked": $euiColorPrimary,
        "colorThumbChecked": #ffffff,
        "colorThumbBorderChecked": #ffffff,
        "colorThumbIconChecked": $euiColorPrimary,
        // Un-checked disabled
        "colorTrackUncheckedDisabled": lightOrDarkTheme(#f2f4f6, #a1a2a5),
        "colorThumbUncheckedDisabled": lightOrDarkTheme(#ffffff, #a1a2a5),
        "colorThumbBorderUncheckedDisabled": lightOrDarkTheme(#f4f4f4, #949597),
        "colorThumbIconUncheckedDisabled": lightOrDarkTheme(#e6e8ea, #898b90),
        // Intermidiate disabled
        "colorTrackIntermidiateDisabled": lightOrDarkTheme(#f2f4f6, #a1a2a5),
        "colorThumbIntermidiateDisabled": lightOrDarkTheme(#ffffff, #a1a2a5),
        "colorThumbBorderIntermidiateDisabled": lightOrDarkTheme(#f4f4f4, #949597),
        "colorThumbIconIntermidiateDisabled": #b9bdc2,
        // Checked disabled
        "colorTrackCheckedDisabled": lightOrDarkTheme(#ffeb80, #8a760f),
        "colorThumbCheckedDisabled": lightOrDakrTheme(#ffffff, #a1a2a5),
        "colorThumbBorderCheckedDisabled": lightOrDarkTheme(#f4f4f4, #7f7f83),
        "colorThumbIconCheckedDisabled": lightOrDarkTheme(#ffeb80, #8a760f)
    );

    $dangerColorMap: (
        // Un-checked
        "colorTrackUnchecked": lightOrDarkTheme(#e1e6eb, #ffffff),
        "colorThumbUnchecked": #ffffff,
        "colorThumbBorderUnchecked": #ffffff,
        "colorThumbIconUnchecked": #e1e6eb,
        // Intermidiate
        "colorTrackIntermidiate": lightOrDarkTheme(#dfe4e9, #e1e6eb),
        "colorThumbIntermidiate": #ffffff,
        "colorThumbBorderIntermidiate": #ffffff,
        "colorThumbIconIntermidiate": #ff9330,
        // Checked
        "colorTrackChecked": #ff9330,
        "colorThumbChecked": #ffffff,
        "colorThumbBorderChecked": #ffffff,
        "colorThumbIconChecked": #ff9330,
        // Un-checked disabled
        "colorTrackUncheckedDisabled": lightOrDarkTheme(#f2f4f6, #a1a2a5),
        "colorThumbUncheckedDisabled": lightOrDarkTheme(#ffffff, #a1a2a5),
        "colorThumbBorderUncheckedDisabled": lightOrDarkTheme(#f4f4f4, #949597),
        "colorThumbIconUncheckedDisabled": lightOrDarkTheme(#e6e8ea, #898b90),
        // Intermidiate disabled
        "colorTrackIntermidiateDisabled": lightOrDarkTheme(#f2f4f6, #a1a2a5),
        "colorThumbIntermidiateDisabled": lightOrDarkTheme(#ffffff, #a1a2a5),
        "colorThumbBorderIntermidiateDisabled": lightOrDarkTheme(#f4f4f4, #949597),
        "colorThumbIconIntermidiateDisabled": #b9bdc2,
        // Checked disabled
        "colorTrackCheckedDisabled": lightOrDarkTheme(#ff9330, #b85900),
        "colorThumbCheckedDisabled": lightOrDakrTheme(#ffffff, #a1a2a5),
        "colorThumbBorderCheckedDisabled": lightOrDarkTheme(#f4f4f4, #7f7f83),
        "colorThumbIconCheckedDisabled": lightOrDarkTheme(#ff9330, #b85900)
    );

    $colorMaps: (
        "primary": $primaryColorMap,
        "danger": $dangerColorMap,
    );

    @each $name, $colors in $colorMaps {
        &--#{$name} {
            &.lxnavTriStateSwitch--checked {
                & .lxnavTriStateSwitch__thumb {
                    background-color: map-get($colors, "colorThumbChecked");
                    border-color: map-get($colors, "colorThumbBorderChecked");

                    & svg {
                        @include colorSvg(map-get($colors, "colorThumbIconChecked"));
                    }
                }

                & .lxnavTriStateSwitch__track {
                    background-color: map-get($colors, "colorTrackChecked");
                }
            }

            &.lxnavTriStateSwitch--unchecked {
                & .lxnavTriStateSwitch__thumb {
                    background-color: map-get($colors, "colorThumbUnchecked");
                    border-color: map-get($colors, "colorThumbBorderUnchecked");

                    & svg {
                        @include colorSvg(map-get($colors, "colorThumbIconUnchecked"));
                    }
                }

                & .lxnavTriStateSwitch__track {
                    background-color: map-get($colors, "colorTrackUnchecked");
                }
            }

            &.lxnavTriStateSwitch--intermidiate {
                & .lxnavTriStateSwitch__thumb {
                    background-color: map-get($colors, "colorThumbIntermidiate");
                    border-color: map-get($colors, "colorThumbBorderIntermidiate");

                    & svg {
                        @include colorSvg(map-get($colors, "colorThumbIconIntermidiate"));
                    }
                }

                & .lxnavTriStateSwitch__track {
                    background-color: map-get($colors, "colorTrackIntermidiate");
                }
            }

            &.lxnavTriStateSwitch--disabled {
                & .lxnavTriStateSwitch__button {
                    &:hover {
                        cursor: not-allowed;
                    }
                }

                & .lxnavTriStateSwitch__thumb {
                    box-shadow: none;
                }

                &.lxnavTriStateSwitch--unchecked {
                    & .lxnavTriStateSwitch__thumb {
                        background-color: map-get($colors, "colorThumbUncheckedDisabled");
                        border-color: map-get($colors, "colorThumbBorderUncheckedDisabled");

                        & svg {
                            @include colorSvg(map-get($colors, "colorThumbIconUncheckedDisabled"));
                        }
                    }

                    & .lxnavTriStateSwitch__track {
                        background-color: map-get($colors, "colorTrackUncheckedDisabled");
                    }
                }

                &.lxnavTriStateSwitch--checked {
                    & .lxnavTriStateSwitch__thumb {
                        background-color: map-get($colors, "colorThumbCheckedDisabled");
                        border-color: map-get($colors, "colorThumbBorderCheckedDisabled");

                        & svg {
                            @include colorSvg(map-get($colors, "colorThumbIconCheckedDisabled"));
                        }
                    }

                    & .lxnavTriStateSwitch__track {
                        background-color: map-get($colors, "colorTrackCheckedDisabled");
                    }
                }

                &.lxnavTriStateSwitch--intermidiate {
                    & .lxnavTriStateSwitch__thumb {
                        background-color: map-get($colors, "colorThumbIntermidiateDisabled");
                        border-color: map-get($colors, "colorThumbBorderIntermidiateDisabled");

                        & svg {
                            @include colorSvg(map-get($colors, "colorThumbIconIntermidiateDisabled"));
                        }
                    }

                    & .lxnavTriStateSwitch__track {
                        background-color: map-get($colors, "colorTrackIntermidiateDisabled");
                    }
                }
            }
        }
    }
}
