.lxnavMapIconButton {
    $backgroundColor: #fff;

    height: 50px;
    width: 50px;
    border-radius: $euiBorderRadius;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    transition: box-shadow $euiAnimSpeedExtraFast;
    background-color: $backgroundColor;

    &:hover:not(:disabled) {
        background-color: darken($backgroundColor, 3) !important;
    }

    &:hover:not(:disabled) {
        cursor: pointer;
    }

    &:active {
        box-shadow: none;
    }

    &:disabled {
        box-shadow: none;

        & .lxnavMapButton__icon {
            @include colorSvg(#bfc5ce);
        }
    }
}
