@mixin euiButton {
    @include euiButtonBase;
    @include euiFont;
    @include euiFontSize;

    font-weight: $euiFontWeightMedium;
    text-decoration: none;
    transition: all $euiAnimSpeedNormal $euiAnimSlightBounce;

    // sass-lint:disable mixins-before-declarations
    // focus states should come after all default styles
    @include euiButtonFocus;

    &:hover:not(:disabled),
    &:focus {
        text-decoration: underline;
    }
}

// Adds the focus (and hover) animation for translating up 1px
@mixin euiButtonFocus {
    transition: unset;

    &:hover:not(:disabled) {
        transform: unset;
    }

    &:focus {
        animation: unset;
    }

    &:active:not(:disabled) {
        transform: unset;
    }
}
