.lxnavWarningBadge {
    display: inline-flex;
    align-items: center;

    border-radius: 5px;
    padding: 3px 4px;

    font-weight: 500;
    line-height: 1;

    &__icon {
        margin-right: 5px;
        max-width: 14px;
    }

    &.lxnavWarningBadge--warning {
        background-color: transparentize($euiColorWarning, 0.9);
    }

    &.lxnavWarningBadge--danger {
        background-color: transparentize($euiColorDanger, 0.9);
    }
}
