.lxnavInputField {
    @include euiFormControlSize($includeAlternates: false);
    @include euiFormControlText;

    border: none;
    background-color: unset;
    box-shadow: none;
    padding: $euiFormControlPadding;

    &:-webkit-autofill {
        -webkit-text-fill-color: lightOrDarkTheme($euiColorDarkestShade, $euiColorLightShade);
    }
}
