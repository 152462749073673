$textColors: (
    subdued: $euiTextSubduedColor,
    primary: $euiColorPrimary,
    secondary: $euiColorSecondary,
    accent: $euiColorAccent,
    warning: $euiColorWarning,
    danger: $euiColorDanger,
    text: $euiTextColor,
    ghost: $euiColorGhost,
);

.euiLink {
    @include euiLink;

    .euiLink__externalIcon {
        margin-left: $euiSizeXS;
    }

    &.euiLink-disabled {
        text-decoration: none;
        cursor: default;
    }

    // Create color modifiers based on the map
    @each $name, $color in $textColors {
        &.euiLink--#{$name} {
            color: $color;

            &:hover {
                color: darken($color, 10%);
            }
        }
    }
}

// Make button EuiLink's text selectable
button.euiLink {
    // sass-lint:disable-line no-qualifying-elements
    user-select: text;
}
