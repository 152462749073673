.lxnavFloatingActionButton {
  $size: 52px;
  $colorLight: #ffffff;
  $colorDark: #2b2d3d;

  width: $size;
  height: $size;
  box-shadow: 0px 2px 14px 0px #0000002e;
  background-color: lightOrDarkTheme($colorLight, $colorDark);
  border-radius: 26px;
  cursor: pointer;

  &.lxnavFloatingActionButton--light {
    background-color: $colorLight;
  }

  &.lxnavFloatingActionButton--dark {
    background-color: $colorDark;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
  }
}