$_size: 43px;
$_color: $cardLevelTwoColor;
$_contentColor: $statBoxIconColor;

.lxnavPinMarker {
    display: inline-block;
    @include markerShadow;

    &__content {
        @include colorSvg($_contentColor);
    }

    &__body {
        fill: #e4eaf0;
    }

    & > svg {
        fill: $cardLevelTwoColor;

        height: $_size;
        width: $_size;
    }

    $colorMap: (
        "danger": $euiColorDanger,
        "warning": $euiColorWarning,
    );

    @each $name, $color in $colorMap {
        &.lxnavPinMarker--#{$name} {
            & .lxnavPinMarker__body {
                fill: $color;
            }

            & .lxnavPinMarker__content {
                @include colorSvg($color);
            }
        }
    }
}
