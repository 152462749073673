.lxnavThumbSwitch {
    $switchWidth: 45px;
    $switchHeight: 20px;
    $switchThumbSize: 25px;

    $switchThumbOffset: 0;

    position: relative;

    &__button {
        flex-shrink: 0; // ensures the button doesn't lose width because of a long label
        line-height: 0; // ensures button takes height of switch inside
    }

    &__body {
        pointer-events: none;
        width: $switchWidth;
        height: $switchHeight;
        background-color: $euiColorEmptyShade;
        display: inline-block;
        position: relative;
        border-radius: $switchHeight;
        vertical-align: middle;
    }

    &__thumb {
        padding: round($switchThumbSize / 2);
        border-radius: $switchThumbSize;
        z-index: 1;

        position: absolute;
        display: inline-block;
        background-color: $euiColorPrimary;
        left: 0;
        filter: saturate(0.5);
        top: round(($switchHeight - $switchThumbSize) / 2);
        transition: border-color $euiAnimSpeedNormal $euiAnimSlightBounce,
            background-color $euiAnimSpeedNormal $euiAnimSlightBounce, left $euiAnimSpeedNormal $euiAnimSlightBounce,
            transform $euiAnimSpeedNormal $euiAnimSlightBounce, filter $euiAnimSpeedNormal, opacity $euiAnimSpeedNormal;

        &__content {
            position: absolute;
            display: inline-block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__track {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        border-radius: $switchHeight;
        background-color: darken($euiColorPrimary, 15%);
        filter: saturate(0.3);
        transition: filter $euiAnimSpeedNormal, opacity $euiAnimSpeedNormal;
    }

    &:hover .lxnavThumbSwitch__button {
        &.lxnavThumbSwitch__thumb {
            transform: scale(1.05);
        }

        &:active .lxnavThumbSwitch__thumb {
            transform: scale(0.95);
        }
    }

    &--checked {
        & .lxnavThumbSwitch__thumb {
            left: $switchWidth - $switchThumbSize - $switchThumbOffset;
            filter: saturate(1);
        }

        & .lxnavThumbSwitch__track {
            filter: saturate(1);
        }
    }

    &--disabled {
        & .lxnavThumbSwitch__button {
            &:hover {
                cursor: not-allowed;
            }
        }

        & .lxnavThumbSwitch__thumb {
            filter: saturate(0) brightness(0.6);
        }

        & .lxnavThumbSwitch__track {
            filter: saturate(0) brightness(0.4);
        }
    }
}
