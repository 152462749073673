.lxnavCaret {
    width: 11px;

    $speeds: (
        "xfast": $euiAnimSpeedExtraFast,
        "fast": $euiAnimSpeedFast,
        "normal": $euiAnimSpeedNormal,
        "slow": $euiAnimSpeedSlow,
        "xslow": $euiAnimSpeedExtraSlow,
    );

    @each $name, $value in $speeds {
        &--animated--#{$name} img {
            transition: transform $value;
        }
    }
}
