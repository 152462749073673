.lxnavSignInPage {
    background-color: lightOrDarkTheme(#fff, #252734);
    overflow-y: auto;
    height: 100%;

    &__imagePanel {
        background-image: url("../../backgrounds/login_image.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        flex-shrink: 0 !important;

        @include euiBreakpoint("s", "xs") {
            flex-basis: auto !important;
            min-height: 200px;
        }

        @include euiBreakpoint("m", "l", "xl") {
            flex-basis: 50% !important;
        }

        &__container {
            @include euiBreakpoint("s", "xs") {
                padding: 30px;
            }

            @include euiBreakpoint("m", "l", "xl") {
                padding: 80px;
            }
        }

        &__logo {
            margin-bottom: 16px;
        }

        &__text {
            font-weight: 600;

            @include euiBreakpoint("s", "xs") {
                font-size: 1.75rem;
            }

            @include euiBreakpoint("m", "l", "xl") {
                font-size: 3.75rem;
            }
        }
    }

    &__formPanel {
        flex-shrink: 0 !important;

        @include euiBreakpoint("s", "xs") {
            padding-top: 20px;
            flex-basis: auto !important;
        }

        @include euiBreakpoint("xl", "l", "m") {
            padding: 46px;
            flex-basis: 50% !important;
        }

        &__noAccount {
            margin-right: 5px;
            color: $euiTextSubduedColor;
        }

        &__form {
            max-width: 240px;
        }

        &__title {
            font-weight: 300;
        }
    }
}
