$euiTableHoverColor: transparentize($euiColorLightShade, 0.75);

/**
 * NOTE: table-layout: fixed causes a bug in IE11 and Edge (see #9929). It also prevents us from
 * specifying a column width, e.g. the checkbox column.
 */
.euiTable {
    @include euiFontSizeS;
    @include euiNumberFormat;

    width: 100%;
    table-layout: fixed;
    border: none;
    border-collapse: collapse;
    background-color: $euiColorEmptyShade;

    &.euiTable--auto {
        table-layout: auto;
    }
}

.euiTableCaption {
    position: relative;
}

// Compressed styles not for mobile
@include euiBreakpoint("m", "l", "xl") {
    .euiTable--compressed {
        .euiTableCellContent {
            @include euiFontSizeXS;
            padding: $euiTableCellContentPaddingCompressed;
        }
    }
}

.euiTableFooterCell,
.euiTableHeaderCell {
    @include euiTableCell;
    @include euiTitle("xxs");
    font-weight: $euiFontWeightMedium;
    border-top: none;

    .euiTableHeaderButton {
        text-align: left;
        font-weight: $euiFontWeightMedium;
    }

    .euiTableCellContent__text {
        @include euiFontSizeXS;
        font-weight: $euiFontWeightSemiBold;
    }
}

.euiTableHeaderButton {
    @include euiFontSizeS;
    color: inherit;
    width: 100%;

    &:hover,
    &:focus {
        .euiTableCellContent__text {
            text-decoration: underline;
            color: $euiColorPrimary;
        }

        .euiTableSortIcon {
            fill: $euiColorPrimary;
        }
    }
}

.euiTableSortIcon {
    margin-left: $euiSizeXS;
    flex-shrink: 0; // makes sure the icon doesn't change size because the text is long

    .euiTableHeaderButton-isSorted & {
        fill: $euiTitleColor;
    }
}

.euiTableHeaderCellCheckbox {
    @include euiTableCellCheckbox;
    border-top: none;
}

.euiTableRow {
    &:hover {
        background-color: $euiTableHoverColor;
    }

    &.euiTableRow-isExpandedRow {
        .euiTableRowCell {
            background-color: $euiTableHoverColor;
        }

        &.euiTableRow-isSelectable .euiTableCellContent {
            padding-left: $euiTableCellCheckboxWidth + $euiTableCellContentPadding;
        }
    }

    &.euiTableRow-isSelected {
        background-color: $euiTableSelectedColor;

        + .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
            background-color: $euiTableSelectedColor;
        }

        &:hover,
        &:hover + .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
            background-color: $euiTableHoverSelectedColor;
        }
    }
}

.euiTableRowCell {
    @include euiTableCell;

    color: $euiTextColor;

    &.euiTableRowCell--isMobileHeader {
        display: none; // Hide if not mobile breakpoint
    }
}

.euiTableRowCellCheckbox {
    @include euiTableCellCheckbox;
}

// Must come after .euiTableRowCell selector for border to be removed
.euiTableFooterCell {
    background-color: $euiColorLightestShade;
    border-bottom: none;
}

/**
 * 1. Vertically align all children.
 * 2. The padding on this div allows the ellipsis to show if the content is truncated. If
 *    the padding was on the cell, the ellipsis would be cropped.
 * 3. Support wrapping.
 * 4. Prevent very long single words (e.g. the name of a field in a document) from overflowing
 *    the cell.
 */
.euiTableCellContent {
    overflow: hidden; /* 4 */
    display: flex;
    align-items: center; /* 1 */
    padding: $euiTableCellContentPadding; /* 2 */
}

.euiTableCellContent__text {
    @include euiTextBreakWord; /* 4 */
    min-width: 0;
    text-overflow: ellipsis;
}

.euiTableCellContent--alignRight {
    justify-content: flex-end;
    text-align: right;
}

.euiTableCellContent--alignCenter {
    justify-content: center;
    text-align: center;
}

.euiTableHeaderCell,
.euiTableFooterCell,
.euiTableCellContent--truncateText {
    white-space: nowrap; /* 3 */

    .euiTableCellContent__text {
        overflow: hidden;
    }
}

.euiTableCellContent--overflowingContent {
    overflow: visible;
    white-space: normal;
    //* 4 */ overflow-wrap is not supported on flex parents
    word-break: break-all; // Fallback for FF and IE
    word-break: break-word;
}

.euiTableCellContent--showOnHover {
    > *:not(:first-child) {
        margin-left: $euiSizeS;
    }
}

.euiTableRow-hasActions {
    .euiTableCellContent--showOnHover {
        .euiTableCellContent__hoverItem {
            flex-shrink: 0;
            opacity: 0.7;
            filter: grayscale(100%);
            transition: opacity $euiAnimSpeedNormal $euiAnimSlightResistance,
                filter $euiAnimSpeedNormal $euiAnimSlightResistance;
        }

        .expandedItemActions__completelyHide,
        .expandedItemActions__completelyHide:disabled,
        .expandedItemActions__completelyHide:disabled:hover,
        .expandedItemActions__completelyHide:disabled:focus,
        .euiTableRow:hover & .expandedItemActions__completelyHide:disabled {
            filter: grayscale(0%);
            opacity: 0;
        }
    }

    &:hover .euiTableCellContent--showOnHover .euiTableCellContent__hoverItem:not(:disabled) {
        &,
        &:hover,
        &:focus {
            opacity: 1;
            filter: grayscale(0%);
        }
    }
}

// Animate expanded row must be on the contents div inside

.euiTableRow-isExpandedRow .euiTableCellContent {
    overflow: hidden;
    animation: $euiAnimSpeedNormal $euiAnimSlightResistance 1 normal forwards growExpandedRow;
}

@keyframes growExpandedRow {
    0% {
        max-height: 0;
    }

    100% {
        max-height: 1000px;
    }
}

.euiTableRow-isClickable {
    &:hover {
        background-color: $euiTableHoverClickableColor;
        cursor: pointer;
    }

    &:focus {
        background-color: $euiTableFocusClickableColor;
    }
}
