// Header items are the small icon links that pop up menus.

.euiHeaderSectionItem {
    position: relative;
    display: flex;
    align-items: center;

    &:after {
        display: none;
    }
}

.euiHeaderSectionItem__button {
    height: $euiHeaderChildSize;
    min-width: $euiHeaderChildSize;
    text-align: center;
    font-size: 0; // aligns icons better vertically

    &:hover {
        background: #fff;
    }

    &:focus {
        background: #fff;
    }
}

.euiHeaderSectionItem--borderLeft {
    &:after {
        left: 0;
        width: 1px;
    }
}

.euiHeaderSectionItem--borderRight {
    &:after {
        width: 1px;
        left: auto;
        right: 0;
    }
}

// SET FOR DEPRECATION: 2/21/20
// The `euiHeaderNotification` class was needed to be manually applied
// Now notifications can be automatically added to the buttons via props
.euiHeaderNotification,
.euiHeaderSectionItemButton__notification {
    position: absolute;
    top: 9%;
    right: 9%;
    box-shadow: 0 0 0 1px $euiHeaderBackgroundColor;
}

@include euiBreakpoint("xs") {
    .euiHeaderSectionItem,
    .euiHeaderSectionItem__button {
        min-width: $euiHeaderChildSize * 0.75;
    }

    .euiHeaderSectionItem--borderLeft,
    .euiHeaderSectionItem--borderRight {
        &:after {
            display: none;
        }
    }

    // On small screens just show a small dot indicating there are notifications
    .euiHeaderNotification,
    .euiHeaderSectionItemButton__notification {
        @include size($euiSizeS);
        top: 20%;
        min-width: 0;
        border-radius: $euiSizeS;
        color: $euiColorAccent;
        overflow: hidden;
    }
}
