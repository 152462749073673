.lxnavShareButton {
    &__disabled {
        &--dark {
            @include colorSvg(#3c3d4c);
        }

        &--light {
            @include colorSvg(#e0e3e7);
        }
    }

    &__active {
        &--dark {
            @include colorSvg($euiColorPrimary);
            fill: $euiColorPrimary;
        }

        &--light {
            @include colorSvg($euiColorPrimary);
            fill: $euiColorPrimary;
        }
    }

    &__inactive {
        &--dark {
            @include colorSvg(#555764);
        }

        &--light {
            @include colorSvg(#c9ccd2);
        }
    }
}
