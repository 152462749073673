.lxnavCard {
  border-radius: 16px;

  &--levelOne {
    background-color: $cardLevelOneColor;
  }

  &--levelTwo {
    background-color: $cardLevelTwoColor;
  }
}