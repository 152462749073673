.lxnavThemeSwitch {
    $themeSwitchWidth: 66px;
    $themeSwitchHeight: 24px;
    $themeSwitchThumbSize: 18px;
    $themeSwitchLabelHeight: 12px;

    $themeSwitchThumbOffset: 3px;
    $themeSwitchLabelOffset: 11px;
    $themeSwitchBorderWidth: 1px;

    &__button {
        position: relative;

        flex-shrink: 0; // ensures the button doesn't lose width because of a long label
        line-height: 0; // ensures button takes height of switch inside

        &--checked {
            // When input is not checked, we shift around the positioning of the thumb and the icon
            .lxnavThemeSwitch__thumb {
                // move the thumb left
                left: $themeSwitchThumbOffset;
            }

            .lxnavThemeSwitch__label {
                // move the icon right
                right: -$themeSwitchLabelOffset;

                &.lxnavThemeSwitch__label--checked {
                    right: auto;
                    left: round($themeSwitchWidth - $themeSwitchThumbSize / 2) * -1;
                }
            }
        }

        &:disabled {
            cursor: not-allowed;

            & .lxnavThemeSwitch__body {
                border-color: $euiButtonColorDisabled;
            }

            & .lxnavThemeSwitch__thumb {
                background-color: $euiButtonColorDisabled;
            }

            & .lxnavThemeSwitch__label {
                color: $euiButtonColorDisabledText;
            }
        }
    }

    &__body {
        pointer-events: none;
        width: $themeSwitchWidth;
        height: $themeSwitchHeight;
        display: inline-block;
        position: relative;
        border: $themeSwitchBorderWidth solid lightOrDarkTheme(#e7e8e9, #404250);
        border-radius: $themeSwitchHeight;
        vertical-align: middle;
    }

    &__thumb {
        padding: round(($themeSwitchThumbSize - 2 * $themeSwitchBorderWidth) / 2);
        border-radius: $themeSwitchThumbSize;

        position: absolute;
        display: inline-block;
        background-color: $euiColorDarkestShade;
        left: $themeSwitchWidth - $themeSwitchThumbSize - $themeSwitchThumbOffset;
        top: round(($themeSwitchHeight - $themeSwitchThumbSize) / 2);
        transition: border-color $euiAnimSpeedNormal $euiAnimSlightBounce,
            background-color $euiAnimSpeedNormal $euiAnimSlightBounce, left $euiAnimSpeedNormal $euiAnimSlightBounce,
            transform $euiAnimSpeedNormal $euiAnimSlightBounce;
    }

    &__track {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        border-radius: $themeSwitchHeight;
    }

    &__label {
        font-size: $themeSwitchLabelHeight;
        line-height: 1;
        position: absolute;
        right: round($themeSwitchWidth - $themeSwitchThumbSize / 2) * -1;
        top: round(($themeSwitchHeight - $themeSwitchLabelHeight) / 2 - $themeSwitchBorderWidth);
        bottom: 0;
        width: round($themeSwitchWidth - $themeSwitchThumbSize / 2 + $themeSwitchLabelOffset);
        height: $themeSwitchLabelHeight;
        transition: left $euiAnimSpeedNormal $euiAnimSlightBounce, right $euiAnimSpeedNormal $euiAnimSlightBounce;
        fill: $euiColorDarkestShade;

        &--checked {
            right: auto;
            left: -$themeSwitchLabelOffset;
            fill: $euiColorDarkestShade;
        }
    }

    &:hover .lxnavThemeSwitch__button {
        &.lxnavThemeSwitch__thumb {
            transform: scale(1.05);
        }

        &:active .lxnavThemeSwitch__thumb {
            transform: scale(0.95);
        }
    }
}
