.lxnavSmallStatBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    min-height: 70px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: $cardLevelTwoColor;
    padding: 6px 10px;

    &.lxnavSmallStatBox--danger {
        background-color: $euiColorDanger;

        & .lxnavSmallStatBox__title {
            color: #ffffff;
        }

        & .lxnavSmallStatBox__icon > svg {
            @include colorSvg(#ffffff);
        }
    }

    &.lxnavSmallStatBox--warning {
        border: 2px solid $euiColorWarning;
        background-color: lighten($euiColorWarning, 35%);

        & .lxnavSmallStatBox__title {
            color: $euiColorWarning;
        }

        & .lxnavSmallStatBox__icon > svg {
            @include colorSvg($euiColorWarning);
        }
    }

    &__innerContent {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
    }

    &__content {
        margin-bottom: 3px;
    }

    &__title {
        color: $statBoxIconColor;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: left;
    }

    &__icon {
        margin-right: 6px;

        & > svg {
            width: 21px;
            height: 21px;
            @include colorSvg($statBoxIconColor);
        }
    }
}
