@mixin lxnavTheme($name) {
    html.#{$name} {
        // Import core stylings
        @import "../../core/index";

        // Import our components styles
        @import "../../components";

        // Import page specific styles
        @import "../../pages";

        // Additional mixin classes
        @import "mixins";

        // Some of the styles target html explicitly
        @include euiFont;
        font-size: $euiFontSize;
        color: $euiTextColor;
        background-color: $euiPageBackgroundColor;
        height: 100%;
    }
}
