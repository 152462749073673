.lxnavSignInForm {
    &__field {
        height: 46px;
        padding: 14px 18px;

        ::placeholder {
            color: $euiColorLightestShade;
        }
    }

    &__password {
        padding: 14px 18px 14px 35px;
    }
}
