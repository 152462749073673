.lxnavNotificationBadge {
    display: inline-block;
    border-radius: 22px;
    background-color: $euiColorDanger;
    color: #ffffff;
    font-weight: 900;
    font-size: 12px;
    line-height: 22px;
    min-width: 22px;
    text-align: center;
    height: 22px;
    padding-left: 5px;
    padding-right: 5px;
}
