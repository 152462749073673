@mixin rippleBase($color: null) {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    background-color: transparent;

    @if $color != null {
        box-shadow: 0 0 1px 1px $color;
    }
}

@mixin smallRippleAnimation($color: null) {
    @keyframes statusIndicatorPulseSmall {
        0% {
            transform: scale(0.1);
            opacity: 1;
        }

        70% {
            transform: scale(2);
            opacity: 0;
        }

        100% {
            opacity: 0;
        }
    }

    @include rippleBase($color);
    animation: statusIndicatorPulseSmall 2.5s infinite ease-out;
    animation-delay: -#{(2.5 - 0.25) * 0.7}s;
}

@mixin largeRippleAnimation($color: null) {
    @keyframes statusIndicatorPulseLarge {
        0% {
            transform: scale(0.1);
            opacity: 1;
        }

        70% {
            transform: scale(3);
            opacity: 0;
        }

        100% {
            opacity: 0;
        }
    }

    @include rippleBase($color);
    animation: statusIndicatorPulseLarge 2.5s infinite ease-out;
    animation-delay: -#{2.5 * 0.7}s;
}
