.lxnavPingAnimation {
    &__container {
        position: absolute;
        display: inline-block;
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }

    @keyframes ping {
        0% {
            transform: scale(3);
        }

        80% {
            transform: scale(0.1);
        }

        100% {
            transform: scale(3);
        }
    }

    animation: ping 1.5s infinite ease-out -#{1.5 * 0.7}s;

    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    background-color: transparent;
}
