.lxnavAccordion {
  @extend .lxnavCard;
  @extend .lxnavCard--levelOne;
  padding: 24px;

  @include euiBreakpoint("s", "xs") {
    background-color: $euiColorLightestShade;
  }

  &__button {
    text-decoration: none;
    & > * {
      width: 100%;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }

    & .euiLink {
      &:hover,
      &:focus {
        text-decoration-color: $euiTextColor;
      }
    }
  }

  &__content {
    padding-bottom: 10px;
  }

  &--selected.lxnavAccordion {
    position: relative;

    @include euiBreakpoint("m", "l", "xl") {
      background-image: linear-gradient(90deg, $euiColorPrimary 2px, $cardLevelOneColor 2px);
    }

    @include euiBreakpoint("s", "xs") {
      background-image: linear-gradient(90deg, $euiColorPrimary 2px, $euiColorLightestShade 2px);
    }

    &::before {
      content: "";
      position: absolute;
      border-width: 5px;
      border-style: solid;
      border-color: transparent;
      border-left-color: $euiColorPrimary;
      top: 35px;
      left: 2px;
    }
  }
}
