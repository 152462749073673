.lxnavDropdown {
    $shadowSpread: 14px;

    position: relative;
    padding-top: $shadowSpread;
    top: 100%;
    left: -$shadowSpread;
    overflow: hidden;
    z-index: $euiZLevel9;
    pointer-events: none;

    &--flushTop {
        padding-top: 0;

        & .lxnavDropdown__content {
            top: 0;
            padding: 0 $shadowSpread $shadowSpread $shadowSpread;
        }
    }

    &__content {
        position: relative;
        top: -$shadowSpread;
        transition: transform $euiAnimSpeedFast;
        display: inline-block;
        padding: $shadowSpread;
        z-index: $euiZLevel9;

        &__inner {
            box-shadow: 0px 2px 14px 0px #0000002e;
            display: inline-block;
            white-space: nowrap;
            pointer-events: all;
        }
    }
}
