.lxnavFormBox {
    &__content {
        &--center {
            & > * {
                margin: auto;
            }
        }

        &--left {
            & > * {
                margin-right: auto;
            }
        }

        &--right {
            & > * {
                margin-left: auto;
            }
        }
    }
}
