.lxnavIconLabel {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 12px;

    &--alignLeft {
        justify-content: flex-start;
    }

    &--alignRight {
        justify-content: flex-end;
    }

    &--iconLeft {
        flex-direction: row;
        & > .lxnavIconLabel__text {
            margin-inline-start: 8px;
            margin-inline-end: 0;
        }
    }

    &--iconRight {
        flex-direction: row-reverse;
        & > .lxnavIconLabel__text {
            margin-inline-start: 0;
            margin-inline-end: 8px;
        }
    }
}
