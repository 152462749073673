.lxnavApplicationFrame {
    height: 100%;

    &__container {
        display: flex;
    }

    &__outerContentContainer {
        @include euiYScroll;
        position: relative;

        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__header {
        z-index: $euiZLevel10;
    }

    &__bottomBar {
        z-index: $euiZLevel8;
    }

    &__userMenu {
        position: fixed;
        right: 0;
        top: $euiHeaderHeightCompensation;
        z-index: $euiZLevel9;

        &__item {
            min-width: 160px;
            padding: 12px 0 14px 17px;
            background-color: $euiColorEmptyShade;

            &:hover {
                background-color: $euiColorPrimary;
                cursor: pointer;
            }
        }

        &__button {
            cursor: pointer;
            user-select: none;
        }
    }

    &__innerContentContainer {
        flex: 1;
    }

    &__navSection {
        background-color: $navBackgroundColor;
    }

    $_navItemSizes: (
        "small": 2px,
        "medium": 9px,
        "large": 20px,
    );

    @each $name, $size in $_navItemSizes {
        &__navItem--#{$name} {
            padding: $size $size 0 $size;
        }
    }

    &--small {
        & .lxnavApplicationFrame__container {
            height: 100%;
        }

        & .lxnavApplicationFrame__navSection {
            z-index: $euiZLevel6;
            background-color: $navBackgroundColor;
            position: fixed;
            bottom: 0;
            width: 100%;
            transition: transform $euiAnimSpeedFast;
        }
    }

    &--large {
        & .lxnavApplicationFrame__container {
            height: 100%;
            display: flex;
            flex-direction: row-reverse;
            align-items: stretch;
        }

        & .lxnavApplicationFrame__navSection {
            @include euiYScroll;

            flex-grow: 0;
        }
    }
}
