/**
 * 1. Fix IE overflow issue (min-height) by adding a separate wrapper for the
 *    flex display. https://github.com/philipwalton/flexbugs#flexbug-3
 * 2. IE has trouble with min-widths on flex elements. Use the pixel value
 *    from our forms since that's usually the smallest we want them.
 */

.euiModal {
    top: $euiHeaderHeightCompensation - 2;

    &.lxnavModal {
        &--hideAppHeader {
            top: 0;
        }
    }
}
