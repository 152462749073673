// Extends euiFlyout
@import "~@elastic/eui/src/components/flyout/_flyout.scss";

.euiCollapsibleNav {
    @include euiFlyout;
    right: auto;
    left: 0;
    max-width: 80vw;

    &:not(.euiCollapsibleNav--isDocked) {
        animation: euiCollapsibleNavIn 350ms ease;
    }
}

.euiCollapsibleNav__closeButton {
    position: absolute;
    right: 0;
    top: $euiSize;
    margin-right: -25%;
}

@include euiBreakpoint("l", "xl") {
    // The addition of this class is handled through JS as well
    // but adding under the breakpoint mixin is an additional fail-safe
    .euiCollapsibleNav.euiCollapsibleNav--isDocked {
        @include euiBottomShadowMedium;

        .euiCollapsibleNav__closeButton {
            display: none;
        }
    }

    .euiCollapsibleNav__toggle--navIsDocked {
        display: none;
    }

    .euiBody--collapsibleNavIsDocked {
        // Shrink the content from the left so it's no longer overlapped by the nav drawer (ALWAYS)
        transition: padding $euiAnimSpeedFast $euiAnimSlightResistance;
    }
}

// Specific keyframes so in comes in from the left
@keyframes euiCollapsibleNavIn {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    75% {
        opacity: 1;
        transform: translateX(0%);
    }
}
