.lxnavGlyphLink {
    $navItemBorderWidth: 2px;
    $selectedNavItemBackgroundColor: lightOrDarkTheme(#fff, #292a39);
    $hoveredNavItemBackgroundColor: lightOrDarkTheme(#fff, #292a39);

    $navigationItemSizes: (
        "small": 45px,
        "medium": 60px,
        "large": 100px,
    );

    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: auto;

    text-align: center;
    color: $navItemColor;

    transition: background-color $euiAnimSpeedNormal, min-height $euiAnimSpeedNormal, min-width $euiAnimSpeedNormal;

    &__label {
        margin-top: 7px;
    }

    &__iconWrapper {
        display: inline-block;
        position: relative;
    }

    &__notification {
        top: 0;
        right: 8px;
        position: absolute;
        transform: translate(100%, -50%);
    }

    // Turn the inner svg black or white depending on the theme
    &__svg {
        width: 26px;
        height: 26px;

        pointer-events: none; // cursor: pointer won't work over the svg without this

        @include colorSvg($navItemColor, true);
    }

    // If the item is selected then paint the correct borde and color the svg
    &.lxnavGlyphLink--selected {
        background-color: $selectedNavItemBackgroundColor;
        color: lightOrDarkTheme(#333646, #fff);

        & .lxnavGlyphLink__svg {
            @include colorSvg($euiColorPrimary);
        }
    }

    &:hover {
        cursor: pointer;
        background-color: $hoveredNavItemBackgroundColor;
        color: lightOrDarkTheme(#000, #fff);

        & .lxnavGlyphLink__svg {
            @include colorSvg(lightOrDarkTheme(#000, #fff));
        }
    }

    @each $name, $size in $navigationItemSizes {
        &.lxnavGlyphLink--#{$name} {
            min-width: $size;
            min-height: $size;

            //Add classes for border painting
            @each $orientation in ("top", "left", "right", "bottom") {
                &--border-#{$orientation} {
                    border-#{$orientation}-style: solid; // Define border width to prevent jittering
                    border-#{$orientation}-width: $navItemBorderWidth;

                    $isHorizontalOrientation: $orientation == "left" or $orientation == "right";
                    min-width: $size - if($isHorizontalOrientation, $navItemBorderWidth, 0);
                    min-height: $size - if($isHorizontalOrientation, 0, $navItemBorderWidth);

                    &.lxnavGlyphLink--selected {
                        border-color: $euiColorPrimary;
                    }
                }

                &:not(&--border-#{$orientation}) {
                    border-radius: round($size / 4.5);
                }
            }
        }
    }
}
