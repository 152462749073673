// Import mapbox stylings
@import "~mapbox-gl/dist/mapbox-gl";

.lxnavMap {
    isolation: isolate;
    $cursors: ("grab", "grabbing", "pointer", "crosshair");

    @each $cursor in $cursors {
        &.lxnavMap__pointer-#{$cursor} .mapboxgl-canvas-container.mapboxgl-interactive {
            cursor: #{$cursor};
        }
    }

    & .mapboxgl-ctrl-bottom-left,
    .mapboxgl-ctrl-bottom-right,
    .mapboxgl-ctrl-top-left,
    .mapboxgl-ctrl-top-right {
        z-index: 5000;
    }

    &--roundBorders {
        border-radius: 10px;
    }

    & .lxnavMapVertexPopup {
        $backgroundColor: rgba($color: #ffffff, $alpha: .7);
        $edgeAnchorPositions: (
            "top": "bottom",
            "bottom": "top",
            "left": "right",
            "right": "left",
            "top-left": "bottom",
            "top-right": "bottom",
            "bottom-left": "top",
            "bottom-right": "top"
        );

        pointer-events: none;

        & .mapboxgl-popup-content {
            pointer-events: none;
            background-color: $backgroundColor;
            color: $euiColorInk;
        }

        @each $position, $border  in $edgeAnchorPositions {
            &.mapboxgl-popup-anchor-#{$position} {
                & .mapboxgl-popup-tip {
                    border-#{$border}-color: $backgroundColor;
                }
            }
        }
    }

    & .lxnavMapPopup {
        $edgeAnchorPositions: ("top-left", "top-right", "bottom-left", "bottom-right");

        color: $euiColorInk;
        opacity: 1;
        z-index: 10000;
        transition: opacity $euiAnimSpeedFast ease-in-out;

        &.lxnavMapPopup--hidden {
            opacity: 0;
        }

        & .mapboxgl-popup-content {
            font-size: $euiFontSize;
            border-radius: 10px;
        }

        @each $position in $edgeAnchorPositions {
            &.mapboxgl-popup-anchor-#{$position} {
                & .mapboxgl-popup-content {
                    border-#{$position}-radius: 0;
                }
            }
        }
    }
}
