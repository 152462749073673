.lxnavSelectableButton {
    height: $euiButtonHeight;
    line-height: $euiButtonHeight; // prevents descenders from getting cut off
    color: $euiTextSubduedColor;
    border-radius: $euiBorderRadius;
    background-color: transparent;
    transition: background-color $euiAnimSpeedFast, color $euiAnimSpeedFast;
    min-width: 120px;

    &:hover {
        cursor: pointer;

        &:not(.lxnavSelectableButton--selected):not(.lxnavSelectableButton--disabled) {
            color: lightOrDarkTheme($euiColorInk, $euiColorGhost);
        }
    }

    &--selected.lxnavSelectableButton {
        color: $euiColorInk;
        background-color: $euiColorPrimary;
    }

    &--disabled.lxnavSelectableButton {
        color: $euiButtonColorDisabledText;

        &:hover {
            cursor: not-allowed;
        }
    }

    &--compressed.lxnavSelectableButton {
        height: $euiButtonHeightSmall;
        line-height: $euiButtonHeightSmall;
        min-width: 100px;
    }
}
