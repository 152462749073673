@import "../common";

$euiColorPrimary: #ffd600;
$euiColorSecondary: #00bfb3;
$euiColorAccent: #fc358e;
$euiColorDanger: $lxnavColorDanger;
$euiColorWarning: $lxnavColorAttention;

$lxnavColorAnchorOn: #a800ab !default;
$lxnavColorAnchorOff: #737475 !default;
$lxnavColorAnchorDisabled: #353535 !default;

$euiLinkColor: $euiColorPrimary; // Weirdly this one wasn't updating with new primary color

// Contrasty text variants
$euiColorPrimaryText: $euiColorInk;
$euiColorSecondaryText: tintOrShade($euiColorPrimaryText, 90%, 0%);
$euiColorAccentText: tintOrShade($euiColorPrimaryText, 70%, 0%);

$euiColorEmptyShade: #21232e;
$euiColorLightestShade: #333646;
$euiColorLightShade: #494b56;
$euiColorMediumShade: #535966;
$euiColorDarkShade: #98a2b3;
$euiColorDarkestShade: #d4dae5;
$euiColorFullShade: #fff;

$euiFormBackgroundColor: #2b2e3c;

$euiTextColor: $euiColorGhost;
$euiTitleColor: $euiColorGhost;

// Variations from core
$euiLinkColor: $euiColorPrimary;
$euiFocusBackgroundColor: #1b1e27;
$euiShadowColor: #000;
$euiShadowColorLarge: #000;
$euiPageBackgroundColor: #2b2d3d;
$euiTextSubduedColor: makeHighContrastColor($euiColorMediumShade);
$euiTitleColor: $euiTextColor;

$euiHeaderBackgroundColor: $euiPageBackgroundColor;

$euiHeaderHeightCompensation: 49px;
$euiNavDrawerWidthCollapsed: 190px;

// Custom color definitions:
$navBackgroundColor: #333547;
$navItemColor: #6d707b;

$cardLevelOneColor: $cardLevelOneColorDark;
$cardLevelTwoColor: $cardLevelTwoColorDark;

$statBoxIconBackgroundColor: $statBoxIconBackgroundColorDark;
$statBoxTitleColor: $statBoxTitleColorDark;
$statBoxIconColor: $statBoxIconColorDark;

$svgLightColor: #4e505a;
