$_shadow: -8px 0 8px 0 rgba($euiShadowColorLarge, 0.3);

%_lxnavFlyout__button {
    border-left: $euiFlyoutBorder;
    box-shadow: $_shadow;

    width: 20px;
    height: 100px;
    background-color: $euiPageBackgroundColor;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;

    &:hover {
        cursor: pointer;
    }
}

.lxnavFlyout {
    box-shadow: $_shadow;

    &__content {
        height: 100%;
        overflow-y: scroll;

        @include euiScrollBar;
    }

    &__button__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 16px;

        path {
            stroke: $euiColorDarkestShade;
        }
    }

    &__closeButton {
        @extend %_lxnavFlyout__button;

        top: 50%;
        transform: translate(-100%, -50%);
        position: absolute;
        z-index: -1;
    }

    &__openButton {
        @extend %_lxnavFlyout__button;

        top: 50%;
        right: 0;
        transform: translateY(-50%);
        position: fixed;
    }
}
