.lxnavLoadingSpinner {
    height: 100%;
    width: 100%;
    position: relative;

    &__container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &__spinner {
        text-align: center;
        padding-bottom: 5px;
    }
}
