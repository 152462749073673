.lxnavChartManager__addButton {
    $iconSize: 126px;
    $iconSizeSmall: 63px;
    $onHoverScaling: 1.1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include euiBreakpoint("xl", "l", "m") {
        padding: 25px 0;
    }

    @include euiBreakpoint("s", "xs") {
        padding: 10px 0;
    }

    &__iconWrapper {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        @include euiBreakpoint("xl", "l", "m") {
            width: $iconSize * $onHoverScaling;
            height: $iconSize * $onHoverScaling;
            margin-bottom: 24px;
        }

        @include euiBreakpoint("s", "xs") {
            width: $iconSizeSmall * $onHoverScaling;
            height: $iconSizeSmall * $onHoverScaling;
            margin-bottom: 12px;
        }

        & > svg {
            transition: transform $euiAnimSpeedFast;

            & > circle {
                fill: $cardLevelTwoColor;
            }

            @include euiBreakpoint("xl", "l", "m") {
                width: $iconSize;
                height: $iconSize;
            }

            @include euiBreakpoint("s", "xs") {
                width: $iconSizeSmall;
                height: $iconSizeSmall;
            }
        }
    }

    &:hover {
        cursor: pointer;

        .lxnavChartManager__addButton__iconWrapper > svg {
            transform: scale($onHoverScaling);
        }
    }
}
