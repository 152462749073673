.lxnavPgnConfigurationGrid {
    display: grid;
    grid-template-columns: minmax(max-content, 1fr) 150px 150px 5px max-content;
    grid-template-rows: auto;
    align-items: center;

    gap: 0 5px;

    &__rowSpacer {
        grid-column: 1 / span 5;
        height: 15px;
    }

    & .parameterizedWarningItem {
        @mixin inputLabel {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0em;
        }

        @mixin levelOneInputLabel {
            @include inputLabel;
            color: lighten($euiColorDanger, 10%);
        }

        @mixin levelTwoInputLabel {
            @include inputLabel;
            color: lighten($euiColorWarning, 10%);
        }

        @mixin error {
            @include euiFontSizeXS;
            padding-top: 2px;
            color: $euiColorDanger;
        }

        &__levelOneLabel {
            @include levelOneInputLabel;
            grid-column: -4;
        }

        &__levelTwoLabel {
            @include levelTwoInputLabel;
            grid-column: -5;
        }

        &__levelOneError {
            @include error;
            grid-column: -4;
        }

        &__levelTwoError {
            @include error;
            grid-column: -5;
        }

        &__label {
            grid-column: 1;
        }

        &__levelOne {
            grid-column: 3;
        }

        &__levelTwo {
            grid-column: 2;
        }

        &__clearButton {
            grid-column: 5;
            justify-self: center;
        }
    }

    & .basicWarningItem {
        &__label {
            grid-column: 1 / span 4;
        }

        &__switch {
            justify-self: end;
            grid-column: 5;
        }
    }

    & .dataSubscriptionItem {
        &__label {
            grid-column: 1;
        }

        &__select {
            grid-column: 3;
        }

        &__switch {
            grid-column: 5;
            justify-self: end;
        }
    }

    &--compressed.lxnavPgnConfigurationGrid {
        grid-template-columns: 110px 110px 1fr max-content;
        gap: 0 2px;

        & .lxnavPgnConfigurationGrid__rowSpacer {
            grid-column: 1 / span 4;
            height: 10px;
        }

        & .parameterizedWarningItem {
            &__label {
                grid-column: 1 / span 4;
                padding-bottom: 4px;
            }

            &__levelOneLabel {
                grid-column: 2;
            }

            &__levelTwoLabel {
                grid-column: 1;
            }

            &__levelOneError {
                grid-column: 2;
            }

            &__levelTwoError {
                grid-column: 1;
            }

            &__levelOne {
                grid-column: 2;
            }

            &__levelTwo {
                grid-column: 1;
            }

            &__clearButton {
                grid-column: 4;
                justify-self: center;
            }
        }

        & .dataSubscriptionItem {
            &__label {
                grid-column: 1 / span 4;
            }

            &__select {
                grid-column: 1;
            }

            &__switch {
                grid-column: 4;
                justify-self: end;
            }
        }
    }
}
