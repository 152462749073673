.lxnavBreadcrumbs {
    display: flex;
    flex-grow: 0;
    flex-basis: auto;
    min-width: 0;

    &__inner {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        min-width: 0;
    }

    & .lxnavBreadcrumb {
        $lightColor: #c9cdd2;
        $darkColor: #51525d;

        $spacing: 12px;

        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 0;
        min-width: 0;

        &:last-child {
            & .lxnavBreadcrumb__text {
                margin-left: $spacing;

                &,
                & a {
                    color: lightOrDarkTheme($euiColorInk, $euiColorGhost);
                }
            }
        }

        &:first-child {
            & .lxnavBreadcrumb__text {
                margin-right: $spacing;
            }
        }

        &:not(:first-child),
        &:not(:last-child) {
            & .lxnavBreadcrumb__text {
                margin: 0 $spacing;
            }
        }

        &__text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &,
            & a {
                color: lightOrDarkTheme($lightColor, $darkColor);
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: 39px;
                letter-spacing: 0em;
                flex-grow: 0;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        &__delimiter > svg {
            width: 9px;
            height: 15px;
            flex-grow: 0;
            @include colorSvg(lightOrDarkTheme($lightColor, $darkColor));
        }
    }
}
