@mixin euiFlyout {
    border-left: $euiFlyoutBorder;
    position: fixed;
    top: $euiHeaderHeightCompensation;
    bottom: 0;
    right: 0;
    height: calc(100% - #{$euiHeaderHeightCompensation});
    z-index: $euiZFlyout;
    background: $euiPageBackgroundColor;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    clip-path: polygon(-20% 0, 100% 0, 100% 100%, -20% 100%);
}

.euiFlyout {
    @include euiFlyout;
    animation: euiFlyout $euiAnimSpeedNormal $euiAnimSlightResistance;

    .euiFlyoutBody__overflowContent {
        padding: 0;
    }
}
