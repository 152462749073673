.lxnavCaretButtonContainer {
    width: 29px;
    height: 29px;

    cursor: pointer;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    &--showCircle {
        border-radius: 50%;
        border: 1px solid $euiColorMediumShade;
    }
}
