// Header. Includes breadcrumbs of nav buttons.

.euiHeader {
    @include euiBottomShadowSmall;

    position: relative;
    z-index: $euiZHeader; // ensure the shadow shows above content
    display: flex;
    justify-content: space-between;
    background: $euiHeaderBackgroundColor;
    border-bottom: $euiBorderThin;

    &--fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        //z-index: $euiZLevel7;
    }
}

.euiBody--headerIsFixed {
    padding-top: $euiHeaderHeightCompensation - 2; // Extra padding to account for the shadow
    width: 100%;
    height: 100%;
}
