$onlineColor: #5cc79a;
$offlineColor: #c53434;

:export {
    onlineColor: $onlineColor;
    offlineColor: $offlineColor;
}

@mixin offlineAnimation {
    @keyframes offlineKeyframes {
        0% {
            background-color: darken($offlineColor, 25%);
        }
        50% {
            background-color: $offlineColor;
        }
        100% {
            background-color: darken($offlineColor, 25%);
        }
    }

    animation-name: offlineKeyframes 2s ease-in-out infinite;
}

@mixin markerShadow {
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.45));
}
